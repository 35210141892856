<template>
    <b-modal :id="windowId" title="Reset Password">
        <b-container fluid>
            <div class="form-group">
                <admin-input 
                    v-model="password"
                    title="Password"
                    name="password"
                    type="password"
                    :errors="serverErrors"
                />
                <admin-input 
                    v-model="passwordConf"
                    title="Confirm password"
                    name="password_conf"
                    type="password"
                    :errors="serverErrors"
                />
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="reset">Reset</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import { notificationMixin } from './../../mixins/notification.mixin';
import { randString } from './../../mixins/rand-string.mixin';
import adminInput from './../Form/dnd-admin-input';

export default {
    components: {adminInput},
    mixins: [notificationMixin, randString],
    data() {
        return {
            userId: null,
            windowId: this.getRandStr(8),
            password: null,
            passwordConf: null,
            serverErrors: []
        }
    },
    methods: {
        show(userId) {
            this.userId = userId;
            this.password = null;
            this.passwordConf = null;
            this.serverErrors = [];
            this.$bvModal.show(this.windowId);
        },
        reset() {
            const url = 'users/'+ this.userId +'/reset';
            this.$http.put(url, {
                    'password': this.password,
                    'password_conf': this.passwordConf
                })
                .then(() => {
                    this.successNtf("Success!", "Test email sent!");
                    this.$bvModal.hide(this.windowId);
                }, this.responseError);
        }
    }
}
</script>